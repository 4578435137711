<template>
  <section>
    <h2>{{ $t("admin.branchMatriz.title") }}</h2>
    <!--selecciono país us o mx-->
    <b-col class="country-group">
      <h5 class="text-left">{{ $t("welcome.country") }}</h5>
      <b-row>
        <a
          href=""
          class="card-country"
          :class="{ active: isActive }"
          @click.prevent="selectedCountry('MX')"
        >
          <img src="@/assets/images/mexico-flag.svg" />
          <p>Mexico</p>
        </a>
        <a
          href=""
          class="card-country"
          :class="{ active: !isActive }"
          @click.prevent="selectedCountry('US')"
        >
          <img src="@/assets/images/eeuu-flag.svg" />
          <p>{{ $t("welcome.usa") }}</p>
        </a>
      </b-row>
      <!-- <b-button
        class="btn btn-blue-dark ml-0 float-right"
        @click="handleSaveConfigDefined"
      >
        {{ $t("admin.users.next") }}
        <b-icon icon="arrow-right" class="icon" aria-hidden="true"></b-icon>
      </b-button> -->
    </b-col>
    <div class="m-3 mt-5">
      <!-- section no predefinida -->
      <h5>{{ $t("admin.branchMatriz.notPredefined") }}</h5>
      <div class="form-undefined">
        <div v-if="!isLoadingUndefined">
          <div class="group-range">
            <b-input-group prepend="0" append="100">
              <b-form-input
                id="range-1"
                v-model="rangeUndefined"
                type="range"
                min="0"
                max="100"
                :disabled="
                  !(
                    configRol.administrador.pestana.branchMatrix &&
                    configRol.administrador.pestana.branchMatrix.acciones.crear
                  )
                "
              ></b-form-input>
            </b-input-group>

            <b-button
              class="btn btn-blue-dark ml-0"
              @click="handleSaveConfigUndefined"
              v-if="
                configRol.administrador.pestana.branchMatrix &&
                configRol.administrador.pestana.branchMatrix.acciones.crear
              "
            >
              {{ $t("form.Save") }}
              <b-icon
                icon="arrow-right"
                class="icon"
                aria-hidden="true"
              ></b-icon>
            </b-button>
          </div>

          <span class="mx-3"
            ><b>{{ $t("admin.branchMatriz.branch") }}:</b>
            {{ rangeUndefined }} %</span
          >
          <span
            ><b>{{ $t("admin.branchMatriz.matrix") }}:</b>
            {{ 100 - rangeUndefined }} %</span
          >
        </div>
        <div v-else>
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </div>
      <hr />
      <h5>{{ $t("admin.branchMatriz.predefined") }}</h5>
      <div
        class="form-defined"
        v-if="
          configRol.administrador.pestana.branchMatrix &&
          configRol.administrador.pestana.branchMatrix.acciones.crear
        "
      >
        <b-row>
          <b-col cols="4">
            <label class="mt-3 font-weight-bold"
              >{{ $t("admin.branchMatriz.branch") }} *</label
            >
            <b-form-select
              v-model="formDefined.isla"
              :options="optionsBranch"
              size="sm"
              class="mt-0 select"
              v-if="!isLoading"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >--
                  {{ $t("admin.branchMatriz.selectBranch") }}
                  --</b-form-select-option
                >
              </template>
            </b-form-select>
            <div v-else>
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </b-col>

          <b-col cols="4">
            <label class="mt-3 font-weight-bold"
              >{{ $t("admin.branchMatriz.matrix") }} *</label
            >
            <b-form-select
              v-model="formDefined.matriz"
              :options="optionMatriz"
              size="sm"
              class="mt-0 select"
              v-if="!isLoading"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >--
                  {{ $t("admin.branchMatriz.selectMatrix") }}
                  --</b-form-select-option
                >
              </template>
            </b-form-select>
            <div v-else>
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </b-col>
        </b-row>
        <div class="mt-3">
          <div class="group-range">
            <b-input-group prepend="0" append="100">
              <b-form-input
                id="range-2"
                v-model="formDefined.rangeDefined"
                number
                type="range"
                min="0"
                max="100"
              ></b-form-input>
            </b-input-group>
            <b-button
              class="btn btn-blue-dark ml-0"
              @click="handleSaveConfigDefined"
              v-if="!isEditDefined"
              :disabled="!isValid"
            >
              {{ $t("admin.users.create") }}
              <b-icon
                icon="arrow-right"
                class="icon"
                aria-hidden="true"
              ></b-icon>
            </b-button>
            <div v-else>
              <b-button
                class="btn btn-blue-dark mr-3"
                @click="handleSaveConfigDefined"
              >
                {{ $t("admin.trm.edit") }}
                <b-icon
                  icon="arrow-right"
                  class="icon"
                  aria-hidden="true"
                ></b-icon>
              </b-button>
              <b-button class="btn btn-blue-dark" @click="modeCreate">
                {{ $t("admin.role.createMode") }}
                <b-icon
                  icon="arrow-right"
                  class="icon"
                  aria-hidden="true"
                ></b-icon>
              </b-button>
            </div>
          </div>

          <span class="mx-3"
            ><b>{{ $t("admin.branchMatriz.branch") }}:</b>
            {{ formDefined.rangeDefined }} %</span
          >
          <span
            ><b>{{ $t("admin.branchMatriz.matrix") }}:</b>
            {{ 100 - formDefined.rangeDefined }} %</span
          >
        </div>
      </div>
      <hr />

      <b-table
        :items="itemsIslasMatriz"
        :fields="fieldsDefined"
        stacked="md"
        show-empty
        hover
        table-variant="light"
        sort-icon-left
        select-mode="single"
        v-if="
          configRol.administrador.pestana.branchMatrix &&
          configRol.administrador.pestana.branchMatrix.acciones.listar
        "
        :busy="isLoadingConfig"
      >
        <template #cell(percent)="row">
          <b>{{ row.item.percent.Isla }}% - {{ row.item.percent.Matriz }}% </b>
        </template>
        <template #cell(matriz)="row">
          {{ nameMatriz(row.item.matriz) }}
        </template>
        <template #cell(actions)="row">
          <b-row>
            <b-button
              size="sm"
              class="mr-2 bg-success"
              @click="handleEditDefined(row.item)"
              v-if="
                configRol.administrador.pestana.branchMatrix &&
                configRol.administrador.pestana.branchMatrix.acciones.crear
              "
            >
              {{ $t("admin.role.edit") }}
            </b-button>
            <b-button
              size="sm"
              class="mr-2 bg-danger"
              @click="handleDeleteDefined(row.item.id)"
              v-if="
                configRol.administrador.pestana.branchMatrix &&
                configRol.administrador.pestana.branchMatrix.acciones.eliminar
              "
            >
              {{ $t("admin.role.delete") }}
            </b-button>
          </b-row>
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
      <b-alert show dismissible v-else>
        <p>
          {{ $t("permissions.msgPermissionsBranch") }}
        </p>
      </b-alert>
      <hr />
      <h5>{{ $t("admin.branchMatriz.exceptions") }}</h5>
      <div
        class="form-defined"
        v-if="
          configRol.administrador.pestana.branchMatrix &&
          configRol.administrador.pestana.branchMatrix.acciones.crear
        "
      >
        <b-row>
          <b-col cols="4">
            <label class="mt-3 font-weight-bold"
              >{{ $t("admin.branchMatriz.category") }} *</label
            >
            <b-form-select
              v-model="formException.category"
              :options="optionsCategory"
              size="sm"
              class="mt-0 select"
              v-if="!isLoading"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >--
                  {{ $t("admin.branchMatriz.selectCategory") }}
                  --</b-form-select-option
                >
              </template>
            </b-form-select>
            <div v-else>
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </b-col>

          <b-col cols="4">
            <label class="mt-3 font-weight-bold"
              >{{ $t("admin.branchMatriz.product") }} *</label
            >
            <b-form-select
              v-model="formException.product"
              :options="optionsProductXCategory"
              size="sm"
              class="mt-0 select"
              v-if="!isLoading"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >--
                  {{ $t("admin.branchMatriz.selectProduct") }}
                  --</b-form-select-option
                >
              </template>
            </b-form-select>
            <div v-else>
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </b-col>
        </b-row>
        <div class="mt-3">
          <div class="group-range">
            <b-input-group prepend="0" append="100">
              <b-form-input
                id="range-3"
                v-model="formException.range"
                type="range"
                number
                min="0"
                max="100"
              ></b-form-input>
            </b-input-group>

            <b-button
              class="btn btn-blue-dark ml-0"
              @click="handleSaveConfigException"
              v-if="!isEditException"
              :disabled="!isValidException"
            >
              {{ $t("admin.users.create") }}
              <b-icon
                icon="arrow-right"
                class="icon"
                aria-hidden="true"
              ></b-icon>
            </b-button>
            <div v-else>
              <b-button
                class="btn btn-blue-dark mr-3"
                @click="handleSaveConfigException"
              >
                {{ $t("admin.trm.edit") }}
                <b-icon
                  icon="arrow-right"
                  class="icon"
                  aria-hidden="true"
                ></b-icon>
              </b-button>
              <b-button class="btn btn-blue-dark" @click="modeCreateException">
                {{ $t("admin.role.createMode") }}
                <b-icon
                  icon="arrow-right"
                  class="icon"
                  aria-hidden="true"
                ></b-icon>
              </b-button>
            </div>
          </div>

          <span class="mx-3"
            ><b>{{ $t("admin.branchMatriz.branch") }}:</b>
            {{ formException.range }} %</span
          >
          <span
            ><b>{{ $t("admin.branchMatriz.matrix") }}:</b>
            {{ 100 - formException.range }} %</span
          >
        </div>
      </div>
      <hr />

      <b-table
        :items="itemsExceptions"
        :fields="fieldsExceptions"
        stacked="md"
        show-empty
        hover
        table-variant="light"
        sort-icon-left
        select-mode="single"
        thead-tr-class="table-matriz-product"
        tbody-tr-class="table-matriz-product"
        :busy="isLoadingException"
        v-if="
          configRol.administrador.pestana.branchMatrix &&
          configRol.administrador.pestana.branchMatrix.acciones.listar
        "
      >
        <template #cell(percent)="row">
          <b>{{ row.item.percent.Isla }}% - {{ row.item.percent.Matriz }}% </b>
        </template>
        <template #cell(actions)="row">
          <b-row>
            <b-button
              size="sm"
              class="mr-2 bg-success"
              @click="handleEditException(row.item)"
              v-if="
                configRol.administrador.pestana.branchMatrix &&
                configRol.administrador.pestana.branchMatrix.acciones.crear
              "
            >
              {{ $t("admin.role.edit") }}
            </b-button>
            <b-button
              size="sm"
              class="mr-2 bg-danger"
              @click="handleDeleteException(row.item.id)"
              v-if="
                configRol.administrador.pestana.branchMatrix &&
                configRol.administrador.pestana.branchMatrix.acciones.eliminar
              "
            >
              {{ $t("admin.role.delete") }}
            </b-button>
          </b-row>
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
      <b-alert show dismissible v-else>
        <p>
          {{ $t("permissions.msgPermissionsBranch") }}
        </p>
      </b-alert>
    </div>
  </section>
</template>

<script>
import { API_ROOT } from "../../Constants/Api";
import { AlertConfirm, AlertForm, ICON } from "../../helpers/Alert";
import { ApiRequest } from "../../helpers/ApiRequest";
export default {
  name: "IslasMatriz",
  data() {
    return {
      rangeUndefined: "100",
      rangeDefined: 100,
      rangeException: 100,
      isActive: false, //false activa US
      choosedCountry: null,
      matrices:null,
      formDefined: {
        isla: null,
        matriz: null,
        rangeDefined: 100,
        id: null,
      },
      formException: {
        category: null,
        product: null,
        range: "100",
        id: null,
      },
      itemsIslasMatriz: [],
      itemsExceptions: [],
      optionsBranch: [],
      optionMatriz: [],
      isLoading: false,
      isEditDefined: false,
      products: null,
      optionsCategory: [],
      optionsProductXCategory: [],
      isEditException: false,
      isLoadingUndefined: false,
      isLoadingConfig: false,
      isLoadingException: false,
    };
  },
  created() {
    this.configRol = this.$store.state.configRol;
    if (!this.configRol.administrador.pestana.branchMatrix?.permiso) {
      this.$router.push({ name: "Welcome" });
      AlertForm(
        `${this.$i18n.t("permissions.unauthorized")} ${this.$i18n.t(
          "admin.branchMatriz.title"
        )}`,
        this.$i18n.t("permissions.validateAdmin"),
        ICON.WARNING,
        "center",
        null
      );
    }
    this.selectedCountry("MX");
  },

  computed: {
    fieldsDefined() {
      return [
        {
          key: "isla",
          label: this.$i18n.t("admin.branchMatriz.branch"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "matriz",
          label: this.$i18n.t("admin.branchMatriz.matrix"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "percent",
          label: `${this.$i18n.t(
            "admin.branchMatriz.distribution"
          )} (${this.$i18n.t("admin.branchMatriz.branch")} - ${this.$i18n.t(
            "admin.branchMatriz.matrix"
          )})`,
        },
        { key: "actions", label: this.$i18n.t("admin.branchMatriz.actions") },
      ];
    },
    fieldsExceptions() {
      return [
        {
          key: "category",
          label: this.$i18n.t("admin.branchMatriz.category"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "product",
          label: this.$i18n.t("admin.branchMatriz.product"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "percent",
          label: `${this.$i18n.t(
            "admin.branchMatriz.distribution"
          )} (${this.$i18n.t("admin.branchMatriz.branch")} - ${this.$i18n.t(
            "admin.branchMatriz.matrix"
          )})`,
        },
        { key: "actions", label: this.$i18n.t("admin.branchMatriz.actions") },
      ];
    },
    isValid() {
      return this.formDefined.isla && this.formDefined.matriz;
    },
    selectCategory() {
      return this.formException.category;
    },
    selectBranch() {
      return this.formDefined.isla;
    },
    isValidException() {
      return this.formException.category && this.formException.product;
    },
  },

  watch: {
    selectCategory() {
      if (this.selectCategory) {
        this.optionsProductXCategory = JSON.parse(
          JSON.stringify(this.products[this.selectCategory])
        );
        this.optionsProductXCategory.unshift(
          this.$i18n.t("admin.branchMatriz.allProducts")
        );
        if (!this.isEditException) this.formException.product = null;
      }
    },
    selectBranch() {
      if (this.selectBranch) {
        if (this.isEditDefined) return;
        let findMatriz = this.optionMatriz.find(
          (el) => el.text === this.selectBranch
        );
        if (findMatriz) this.formDefined.matriz = findMatriz.value;
        else this.formDefined.matriz = null;
      }
    },

  },
  methods: {
    //cambiar la tabla que lista la isla -matriz segun pais
    async selectedCountry(country) {
      this.isLoading = true;
      this.isLoadingUndefined = true;
      this.isLoadingConfig = true;
      this.isLoadingException = true;
      this.isActive = country === "US" ? false : true;
      this.choosedCountry = country;
      try {
        await this.$store.dispatch("optionsBranch", country);
        await this.$store.dispatch("getProductsXCountry", country); // MX es 1
      } catch ({ message }) {
        let err = JSON.parse(message);
        AlertForm(
          `Error Code: ${err.status}`,
          err.statusText || this.$i18n.t("permissions.errorBranch"),
          ICON.ERROR
        );
      }
      this.getDefinedBranchList();
      this.getExceptionList(country);

      this.getUndefinedBranchConfig(country);

      let branchData = this.$store.state.optionsBranch;

      this.products = this.$store.state.productsXCountry;
      this.optionsCategory = Object.keys(this.products).map((el) => ({
        text: el,
        value: el,
      }));

      this.optionsBranch =
        country === "US" ? branchData.usBranch : branchData.mxBranch;
      this.optionMatriz =
        country === "US" ? branchData.destinoUS : branchData.destinoMX;
        

      this.isLoading = false;
      this.isLoadingUndefined = false;
      this.isLoadingConfig = false;
      this.isLoadingException = false;
    },

    async getUndefinedBranchConfig(country) {
      this.isLoadingUndefined = true;
      try {

        let apiEndpoint;
        // apiEndpoint =`${API_ROOT}configuraciones/key/valor_no_definido_isla`;
        // console.log('api endpoint: ', apiEndpoint)


        if (country == 'MX') {
          console.log('pais isla en getundefinedbranchconfig mx....',country)
          apiEndpoint = `${API_ROOT}configuraciones/key/valor_no_definido_isla`;
        } else if (country == 'US') {
          console.log('pais isla en getundefinedbranchconfig us....',country)
          apiEndpoint = `${API_ROOT}configuraciones/key/valor_no_definido_isla_usa`;

        }
        else {
          console.log('no trae endpoint valor_no_definido_isla mx ni usa')
        }


        let data = await ApiRequest.get(apiEndpoint);

        this.rangeUndefined = data.lista[0].value;
        } catch (err) {
          AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        } 
        this.isLoadingUndefined = false;



    },
    async getDefinedBranchList() {
      this.itemsIslasMatriz = [];
      // this.matrices = [];
      try {
        this.isLoadingConfig = true;
        let data = await ApiRequest.get(
          `${API_ROOT}islamatrizpredefinida/list`
        );


        // comparar datos para ver que isla pertenece a branch
        for (const option of this.optionsBranch) {
          for (const item of data.lista) {
            // Verificar si 'value' en 'option' es igual a 'isla' en 'item'
            if (option.value === item.isla) {
              this.itemsIslasMatriz.push({
                id: item._id,
                isla: item.isla,
                matriz: item.codigomatriz,
                percent: {
                  Isla: item.porcentajeisla,
                  Matriz: item.porcentajematriz,
                },
              });
            }
          }
        }
      } catch (err) {
        if (err.status !== 401) {
          AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        }
      } finally {
        this.isLoadingConfig = false;
      }
    },


    // async getExceptionList(country) {
    //   console.log(country)
    //   try {
    //     let exceptionsList = null;
    //     this.isLoadingException = true;
    //     let data = await ApiRequest.get(`${API_ROOT}islamatrizexcepciones/list`);
    //       data.lista.foreach(exc => {
    //         if (exc.country === country) {
    //           exceptionsList.push(exc)
    //         }  
    //     }
    //     );
    //     console.log(exceptionsList);

    //     this.itemsExceptions = data.lista.map((el) => {
    //       let allProducts = null;
    //       if (['todos'].includes(el.producto)) {
    //         allProducts = this.$i18n.locale === 'es' ? 'Todos los Productos' : 'All Products';
    //       }
    //       return {
    //         id: el._id,
    //         category: el.categoria,
    //         product: allProducts || el.producto,
    //         percent: { Isla: el.porcentajeisla, Matriz: el.porcentajematriz },
    //       };
    //     });
    //   } catch (err) {
    //     if (err.status !== 401) {
    //       AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
    //     }
    //   } finally {
    //     this.isLoadingException = false;
    //   }
    // },

    async getExceptionList(country) {
      try {
        console.log('Entrando a getExceptionList...');
        let exceptionsByCountry = [];
        this.isLoadingException = true;
        let data = await ApiRequest.get(`${API_ROOT}islamatrizexcepciones/list`);

        for (const i in data.lista) {
          if (data.lista[i].country === country) {
            exceptionsByCountry.push(data.lista[i]);
          }
        }
        this.itemsExceptions = exceptionsByCountry.map((el) => {
          let allProducts = null;
          if (["todos"].includes(el.producto)) {
            allProducts =
              this.$i18n.locale === "es"  
                ? "Todos los Productos"
                : "All Products";
          }
          return {
            id: el._id,
            category: el.categoria,
            product: allProducts || el.producto,
            percent: { Isla: el.porcentajeisla, Matriz: el.porcentajematriz },
            country: el.country,
          };
        });
        console.log('exception list..',this.itemsExceptions);
        console.log('Saliendo de getExceptionList...');
      } catch (err) {
        if (err.status !== 401) {
          AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        }
      } 
      finally {
        this.isLoadingException = false;
      }
    },

    
    async handleSaveConfigUndefined() {
      let matrizCalc = 100 - this.rangeUndefined;
      try {
        const keyIsla = this.choosedCountry === 'US' ? 'valor_no_definido_isla_usa' : 'valor_no_definido_isla';
        const keyMatriz = this.choosedCountry === 'US' ? 'valor_no_definido_matriz_usa' : 'valor_no_definido_matriz';

        await ApiRequest.post(`${API_ROOT}configuraciones/crear`, {
          key: keyIsla,
          value: this.rangeUndefined,
        });

        await ApiRequest.post(`${API_ROOT}configuraciones/crear`, {
          key: keyMatriz,
          value: matrizCalc.toString(),
        });

        AlertForm(
          this.$i18n.t("admin.branchMatriz.success"),
          this.$i18n.t("admin.branchMatriz.msgSuccess"),
          ICON.SUCCESS,
          "center"
        );
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },



    async handleSaveConfigDefined() {
      if (this.isEditDefined) {
        try {
          let found = this.optionMatriz.find(
            (el) => el.value === this.formDefined.matriz
          );
          await ApiRequest.put(`${API_ROOT}islamatrizpredefinida/update`, {
            id: this.formDefined.id,
            isla: this.formDefined.isla,
            nombrematriz: found.text,
            codigomatriz: this.formDefined.matriz,
            porcentajeisla: this.formDefined.rangeDefined,
            porcentajematriz: 100 - this.formDefined.rangeDefined,
          });
          AlertForm(
            this.$i18n.t("admin.branchMatriz.success"),
            this.$i18n.t("admin.branchMatriz.msgUpdate"),
            ICON.SUCCESS,
            "center"
          );
          await this.getDefinedBranchList();
          this.cleanFormDefined();
          this.modeCreate();
        } catch (err) {
          AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        }
      } else {
        try {
          let found = this.optionMatriz.find(
            (el) => el.value === this.formDefined.matriz
          );
          await ApiRequest.post(`${API_ROOT}islamatrizpredefinida/crear`, {
            isla: this.formDefined.isla,
            nombrematriz: found.text,
            codigomatriz: this.formDefined.matriz,
            porcentajeisla: this.formDefined.rangeDefined,
            porcentajematriz: 100 - this.formDefined.rangeDefined,
          });
          AlertForm(
            this.$i18n.t("admin.branchMatriz.success"),
            this.$i18n.t("admin.branchMatriz.msgSuccess"),
            ICON.SUCCESS,
            "center"
          );
          await this.getDefinedBranchList();
          
          this.cleanFormDefined();
        } catch (err) {
          AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        }
      }
    },
    modeCreate() {
      this.isEditDefined = false;
      this.cleanFormDefined();
    },
    cleanFormDefined() {
      this.formDefined = {
        isla: null,
        matriz: null,
        rangeDefined: 100,
        id: null,
      };
    },
    handleEditDefined(item) {
      this.isEditDefined = true;
      this.formDefined = {
        isla: item.isla,
        matriz: item.matriz,
        rangeDefined: item.percent.Isla,
        id: item.id,
      };
    },
    handleDeleteDefined(id) {
      AlertConfirm(
        this.$i18n.t("admin.branchMatriz.deleteConfiguration"),
        this.$i18n.t("admin.branchMatriz.questionContinue"),
        ICON.WARNING,
        () => this.deleteConfig(id)
      );
    },
    async deleteConfig(id) {
      try {
        await ApiRequest.delete(`${API_ROOT}islamatrizpredefinida/delete`, {
          id,
        });
        AlertForm(
          this.$i18n.t("admin.branchMatriz.success"),
          this.$i18n.t("admin.branchMatriz.msgDelete"),
          ICON.SUCCESS,
          "center"
        );
        this.cleanFormDefined();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
    },

    async handleSaveConfigException() {
      console.log('Entrando a handleSaveConfigException...')
      let allProducts = null;
      if (
        ["All Products", "Todos los Productos"].includes(
          this.formException.product
        )
      ) {
        allProducts = "todos";
      }
      if (this.isEditException) {
        try {
          console.log('Antes de la actualización...');
          await ApiRequest.put(`${API_ROOT}islamatrizexcepciones/update`, {
            id: this.formException.id,
            categoria: this.formException.category,
            producto: allProducts || this.formException.product,
            porcentajeisla: this.formException.range,
            porcentajematriz: 100 - this.formException.range,
            country: this.choosedCountry === "US" ? 'US' : 'MX',


          });
          console.log('Después de la actualización...');
          AlertForm(
            this.$i18n.t("admin.branchMatriz.success"),
            this.$i18n.t("admin.branchMatriz.msgUpdate"),
            ICON.SUCCESS,
            "center"
          );

          this.modeCreateException();
          this.cleanFormException();
        } catch (err) {
          AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        }
      } else {
        try { console.log('aqui viene el country....',this.country),
          await ApiRequest.post(`${API_ROOT}islamatrizexcepciones/crear`, {
            categoria: this.formException.category,
            producto: allProducts || this.formException.product,
            porcentajeisla: this.formException.range,
            porcentajematriz: 100 - this.formException.range,
           
            country: this.choosedCountry === "US" ? 'US' : 'MX',


          });
          AlertForm(
            this.$i18n.t("admin.branchMatriz.success"),
            this.$i18n.t("admin.branchMatriz.msgSuccess"),
            ICON.SUCCESS,
            "center"
          );
          this.cleanFormException();
        } catch (err) {
          AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        }
      }
      await this.getExceptionList(this.choosedCountry);
    },

    
    modeCreateException() {
      this.isEditException = false;
      this.cleanFormException();
    },
    cleanFormException() {
      this.formException = {
        category: null,
        product: null,
        range: 100,
        id: null,
        country: null,
      };
    },
    handleEditException(item) {
      this.isEditException = true;
      this.formException = {
        category: item.category,
        product: item.product,
        range: item.percent.Isla,
        id: item.id,
        country: item.country,

      };

    },
    handleDeleteException(id) {
      AlertConfirm(
        this.$i18n.t("admin.branchMatriz.deleteConfiguration"),
        this.$i18n.t("admin.branchMatriz.questionContinue"),
        ICON.WARNING,
        () => this.deleteConfigProduct(id)
      );
    },
    async deleteConfigProduct(id) {
      try {
        await ApiRequest.delete(`${API_ROOT}islamatrizexcepciones/delete`, {
          id,
        });
        AlertForm(
          this.$i18n.t("admin.branchMatriz.success"),
          this.$i18n.t("admin.branchMatriz.msgDelete"),
          ICON.SUCCESS,
          "center"
        );
        await this.getExceptionList(this.country);
        this.cleanFormException();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
      }
      await this.getExceptionList(this.choosedCountry);
    },
    nameMatriz(code) {
      let find = this.optionMatriz.find((el) => el.value === code);
      return find
        ? find.text
        : this.$i18n.t("admin.branchMatriz.associatedMatrix");
    },
  },
};
</script>

<style scoped>
@import "/assets/css/global.css";
.title-page {
  margin: 60px 0px;
  color: #707070;
}
.country-group {
  background: #fff;
  padding: 20px;
  margin-right: 30px;
}

.subcard__container {
  display: flex;
  flex-direction: column;
}
.navbar-profile {
  border-radius: 20px;
}

.btn-blue-dark .icon {
  align-self: center;
  margin-left: 10px;
}

.card-country {
  /* width: 130px;
  height: 130px; */
  padding: 10px;
  margin: 10px;
}

.card-country img {
  width: 60px;
}







.group-range {
  display: flex;
  align-items: center;
}
.group-range > *:first-child {
  width: 400px;
  margin-right: 2rem;
}
</style>
