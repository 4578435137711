var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("admin.branchMatriz.title")))]),
      _c(
        "b-col",
        { staticClass: "country-group" },
        [
          _c("h5", { staticClass: "text-left" }, [
            _vm._v(_vm._s(_vm.$t("welcome.country")))
          ]),
          _c("b-row", [
            _c(
              "a",
              {
                staticClass: "card-country",
                class: { active: _vm.isActive },
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.selectedCountry("MX")
                  }
                }
              },
              [
                _c("img", {
                  attrs: { src: require("@/assets/images/mexico-flag.svg") }
                }),
                _c("p", [_vm._v("Mexico")])
              ]
            ),
            _c(
              "a",
              {
                staticClass: "card-country",
                class: { active: !_vm.isActive },
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.selectedCountry("US")
                  }
                }
              },
              [
                _c("img", {
                  attrs: { src: require("@/assets/images/eeuu-flag.svg") }
                }),
                _c("p", [_vm._v(_vm._s(_vm.$t("welcome.usa")))])
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "m-3 mt-5" },
        [
          _c("h5", [
            _vm._v(_vm._s(_vm.$t("admin.branchMatriz.notPredefined")))
          ]),
          _c("div", { staticClass: "form-undefined" }, [
            !_vm.isLoadingUndefined
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "group-range" },
                    [
                      _c(
                        "b-input-group",
                        { attrs: { prepend: "0", append: "100" } },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "range-1",
                              type: "range",
                              min: "0",
                              max: "100",
                              disabled: !(
                                _vm.configRol.administrador.pestana
                                  .branchMatrix &&
                                _vm.configRol.administrador.pestana.branchMatrix
                                  .acciones.crear
                              )
                            },
                            model: {
                              value: _vm.rangeUndefined,
                              callback: function($$v) {
                                _vm.rangeUndefined = $$v
                              },
                              expression: "rangeUndefined"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.configRol.administrador.pestana.branchMatrix &&
                      _vm.configRol.administrador.pestana.branchMatrix.acciones
                        .crear
                        ? _c(
                            "b-button",
                            {
                              staticClass: "btn btn-blue-dark ml-0",
                              on: { click: _vm.handleSaveConfigUndefined }
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("form.Save")) + " "),
                              _c("b-icon", {
                                staticClass: "icon",
                                attrs: {
                                  icon: "arrow-right",
                                  "aria-hidden": "true"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("span", { staticClass: "mx-3" }, [
                    _c("b", [
                      _vm._v(_vm._s(_vm.$t("admin.branchMatriz.branch")) + ":")
                    ]),
                    _vm._v(" " + _vm._s(_vm.rangeUndefined) + " %")
                  ]),
                  _c("span", [
                    _c("b", [
                      _vm._v(_vm._s(_vm.$t("admin.branchMatriz.matrix")) + ":")
                    ]),
                    _vm._v(" " + _vm._s(100 - _vm.rangeUndefined) + " %")
                  ])
                ])
              : _c("div", [_c("b-spinner", { staticClass: "align-middle" })], 1)
          ]),
          _c("hr"),
          _c("h5", [_vm._v(_vm._s(_vm.$t("admin.branchMatriz.predefined")))]),
          _vm.configRol.administrador.pestana.branchMatrix &&
          _vm.configRol.administrador.pestana.branchMatrix.acciones.crear
            ? _c(
                "div",
                { staticClass: "form-defined" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "label",
                            { staticClass: "mt-3 font-weight-bold" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("admin.branchMatriz.branch")) +
                                  " *"
                              )
                            ]
                          ),
                          !_vm.isLoading
                            ? _c("b-form-select", {
                                staticClass: "mt-0 select",
                                attrs: {
                                  options: _vm.optionsBranch,
                                  size: "sm"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "first",
                                      fn: function() {
                                        return [
                                          _c(
                                            "b-form-select-option",
                                            {
                                              attrs: {
                                                value: null,
                                                disabled: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "-- " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "admin.branchMatriz.selectBranch"
                                                    )
                                                  ) +
                                                  " --"
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3859871642
                                ),
                                model: {
                                  value: _vm.formDefined.isla,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formDefined, "isla", $$v)
                                  },
                                  expression: "formDefined.isla"
                                }
                              })
                            : _c(
                                "div",
                                [
                                  _c("b-spinner", {
                                    staticClass: "align-middle"
                                  })
                                ],
                                1
                              )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "label",
                            { staticClass: "mt-3 font-weight-bold" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("admin.branchMatriz.matrix")) +
                                  " *"
                              )
                            ]
                          ),
                          !_vm.isLoading
                            ? _c("b-form-select", {
                                staticClass: "mt-0 select",
                                attrs: {
                                  options: _vm.optionMatriz,
                                  size: "sm"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "first",
                                      fn: function() {
                                        return [
                                          _c(
                                            "b-form-select-option",
                                            {
                                              attrs: {
                                                value: null,
                                                disabled: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "-- " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "admin.branchMatriz.selectMatrix"
                                                    )
                                                  ) +
                                                  " --"
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  957799989
                                ),
                                model: {
                                  value: _vm.formDefined.matriz,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formDefined, "matriz", $$v)
                                  },
                                  expression: "formDefined.matriz"
                                }
                              })
                            : _c(
                                "div",
                                [
                                  _c("b-spinner", {
                                    staticClass: "align-middle"
                                  })
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "group-range" },
                      [
                        _c(
                          "b-input-group",
                          { attrs: { prepend: "0", append: "100" } },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "range-2",
                                number: "",
                                type: "range",
                                min: "0",
                                max: "100"
                              },
                              model: {
                                value: _vm.formDefined.rangeDefined,
                                callback: function($$v) {
                                  _vm.$set(_vm.formDefined, "rangeDefined", $$v)
                                },
                                expression: "formDefined.rangeDefined"
                              }
                            })
                          ],
                          1
                        ),
                        !_vm.isEditDefined
                          ? _c(
                              "b-button",
                              {
                                staticClass: "btn btn-blue-dark ml-0",
                                attrs: { disabled: !_vm.isValid },
                                on: { click: _vm.handleSaveConfigDefined }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("admin.users.create")) +
                                    " "
                                ),
                                _c("b-icon", {
                                  staticClass: "icon",
                                  attrs: {
                                    icon: "arrow-right",
                                    "aria-hidden": "true"
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "btn btn-blue-dark mr-3",
                                    on: { click: _vm.handleSaveConfigDefined }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("admin.trm.edit")) +
                                        " "
                                    ),
                                    _c("b-icon", {
                                      staticClass: "icon",
                                      attrs: {
                                        icon: "arrow-right",
                                        "aria-hidden": "true"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "btn btn-blue-dark",
                                    on: { click: _vm.modeCreate }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("admin.role.createMode")
                                        ) +
                                        " "
                                    ),
                                    _c("b-icon", {
                                      staticClass: "icon",
                                      attrs: {
                                        icon: "arrow-right",
                                        "aria-hidden": "true"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                      ],
                      1
                    ),
                    _c("span", { staticClass: "mx-3" }, [
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm.$t("admin.branchMatriz.branch")) + ":"
                        )
                      ]),
                      _vm._v(" " + _vm._s(_vm.formDefined.rangeDefined) + " %")
                    ]),
                    _c("span", [
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm.$t("admin.branchMatriz.matrix")) + ":"
                        )
                      ]),
                      _vm._v(
                        " " + _vm._s(100 - _vm.formDefined.rangeDefined) + " %"
                      )
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _c("hr"),
          _vm.configRol.administrador.pestana.branchMatrix &&
          _vm.configRol.administrador.pestana.branchMatrix.acciones.listar
            ? _c("b-table", {
                attrs: {
                  items: _vm.itemsIslasMatriz,
                  fields: _vm.fieldsDefined,
                  stacked: "md",
                  "show-empty": "",
                  hover: "",
                  "table-variant": "light",
                  "sort-icon-left": "",
                  "select-mode": "single",
                  busy: _vm.isLoadingConfig
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(percent)",
                      fn: function(row) {
                        return [
                          _c("b", [
                            _vm._v(
                              _vm._s(row.item.percent.Isla) +
                                "% - " +
                                _vm._s(row.item.percent.Matriz) +
                                "% "
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(matriz)",
                      fn: function(row) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.nameMatriz(row.item.matriz)) + " "
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(actions)",
                      fn: function(row) {
                        return [
                          _c(
                            "b-row",
                            [
                              _vm.configRol.administrador.pestana
                                .branchMatrix &&
                              _vm.configRol.administrador.pestana.branchMatrix
                                .acciones.crear
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 bg-success",
                                      attrs: { size: "sm" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleEditDefined(row.item)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("admin.role.edit")) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.configRol.administrador.pestana
                                .branchMatrix &&
                              _vm.configRol.administrador.pestana.branchMatrix
                                .acciones.eliminar
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 bg-danger",
                                      attrs: { size: "sm" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleDeleteDefined(
                                            row.item.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("admin.role.delete")) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "table-busy",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center text-primary my-2" },
                            [
                              _c("b-spinner", { staticClass: "align-middle" }),
                              _c("strong", [_vm._v("Loading...")])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2605142385
                )
              })
            : _c("b-alert", { attrs: { show: "", dismissible: "" } }, [
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("permissions.msgPermissionsBranch")) +
                      " "
                  )
                ])
              ]),
          _c("hr"),
          _c("h5", [_vm._v(_vm._s(_vm.$t("admin.branchMatriz.exceptions")))]),
          _vm.configRol.administrador.pestana.branchMatrix &&
          _vm.configRol.administrador.pestana.branchMatrix.acciones.crear
            ? _c(
                "div",
                { staticClass: "form-defined" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "label",
                            { staticClass: "mt-3 font-weight-bold" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("admin.branchMatriz.category")) +
                                  " *"
                              )
                            ]
                          ),
                          !_vm.isLoading
                            ? _c("b-form-select", {
                                staticClass: "mt-0 select",
                                attrs: {
                                  options: _vm.optionsCategory,
                                  size: "sm"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "first",
                                      fn: function() {
                                        return [
                                          _c(
                                            "b-form-select-option",
                                            {
                                              attrs: {
                                                value: null,
                                                disabled: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "-- " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "admin.branchMatriz.selectCategory"
                                                    )
                                                  ) +
                                                  " --"
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  3147671262
                                ),
                                model: {
                                  value: _vm.formException.category,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formException, "category", $$v)
                                  },
                                  expression: "formException.category"
                                }
                              })
                            : _c(
                                "div",
                                [
                                  _c("b-spinner", {
                                    staticClass: "align-middle"
                                  })
                                ],
                                1
                              )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "4" } },
                        [
                          _c(
                            "label",
                            { staticClass: "mt-3 font-weight-bold" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("admin.branchMatriz.product")) +
                                  " *"
                              )
                            ]
                          ),
                          !_vm.isLoading
                            ? _c("b-form-select", {
                                staticClass: "mt-0 select",
                                attrs: {
                                  options: _vm.optionsProductXCategory,
                                  size: "sm"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "first",
                                      fn: function() {
                                        return [
                                          _c(
                                            "b-form-select-option",
                                            {
                                              attrs: {
                                                value: null,
                                                disabled: ""
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "-- " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "admin.branchMatriz.selectProduct"
                                                    )
                                                  ) +
                                                  " --"
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  488444229
                                ),
                                model: {
                                  value: _vm.formException.product,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formException, "product", $$v)
                                  },
                                  expression: "formException.product"
                                }
                              })
                            : _c(
                                "div",
                                [
                                  _c("b-spinner", {
                                    staticClass: "align-middle"
                                  })
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "group-range" },
                      [
                        _c(
                          "b-input-group",
                          { attrs: { prepend: "0", append: "100" } },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "range-3",
                                type: "range",
                                number: "",
                                min: "0",
                                max: "100"
                              },
                              model: {
                                value: _vm.formException.range,
                                callback: function($$v) {
                                  _vm.$set(_vm.formException, "range", $$v)
                                },
                                expression: "formException.range"
                              }
                            })
                          ],
                          1
                        ),
                        !_vm.isEditException
                          ? _c(
                              "b-button",
                              {
                                staticClass: "btn btn-blue-dark ml-0",
                                attrs: { disabled: !_vm.isValidException },
                                on: { click: _vm.handleSaveConfigException }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("admin.users.create")) +
                                    " "
                                ),
                                _c("b-icon", {
                                  staticClass: "icon",
                                  attrs: {
                                    icon: "arrow-right",
                                    "aria-hidden": "true"
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "btn btn-blue-dark mr-3",
                                    on: { click: _vm.handleSaveConfigException }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("admin.trm.edit")) +
                                        " "
                                    ),
                                    _c("b-icon", {
                                      staticClass: "icon",
                                      attrs: {
                                        icon: "arrow-right",
                                        "aria-hidden": "true"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "btn btn-blue-dark",
                                    on: { click: _vm.modeCreateException }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("admin.role.createMode")
                                        ) +
                                        " "
                                    ),
                                    _c("b-icon", {
                                      staticClass: "icon",
                                      attrs: {
                                        icon: "arrow-right",
                                        "aria-hidden": "true"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                      ],
                      1
                    ),
                    _c("span", { staticClass: "mx-3" }, [
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm.$t("admin.branchMatriz.branch")) + ":"
                        )
                      ]),
                      _vm._v(" " + _vm._s(_vm.formException.range) + " %")
                    ]),
                    _c("span", [
                      _c("b", [
                        _vm._v(
                          _vm._s(_vm.$t("admin.branchMatriz.matrix")) + ":"
                        )
                      ]),
                      _vm._v(" " + _vm._s(100 - _vm.formException.range) + " %")
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _c("hr"),
          _vm.configRol.administrador.pestana.branchMatrix &&
          _vm.configRol.administrador.pestana.branchMatrix.acciones.listar
            ? _c("b-table", {
                attrs: {
                  items: _vm.itemsExceptions,
                  fields: _vm.fieldsExceptions,
                  stacked: "md",
                  "show-empty": "",
                  hover: "",
                  "table-variant": "light",
                  "sort-icon-left": "",
                  "select-mode": "single",
                  "thead-tr-class": "table-matriz-product",
                  "tbody-tr-class": "table-matriz-product",
                  busy: _vm.isLoadingException
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(percent)",
                      fn: function(row) {
                        return [
                          _c("b", [
                            _vm._v(
                              _vm._s(row.item.percent.Isla) +
                                "% - " +
                                _vm._s(row.item.percent.Matriz) +
                                "% "
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(actions)",
                      fn: function(row) {
                        return [
                          _c(
                            "b-row",
                            [
                              _vm.configRol.administrador.pestana
                                .branchMatrix &&
                              _vm.configRol.administrador.pestana.branchMatrix
                                .acciones.crear
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 bg-success",
                                      attrs: { size: "sm" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleEditException(
                                            row.item
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("admin.role.edit")) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.configRol.administrador.pestana
                                .branchMatrix &&
                              _vm.configRol.administrador.pestana.branchMatrix
                                .acciones.eliminar
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 bg-danger",
                                      attrs: { size: "sm" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleDeleteException(
                                            row.item.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("admin.role.delete")) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "table-busy",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center text-primary my-2" },
                            [
                              _c("b-spinner", { staticClass: "align-middle" }),
                              _c("strong", [_vm._v("Loading...")])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3698642473
                )
              })
            : _c("b-alert", { attrs: { show: "", dismissible: "" } }, [
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("permissions.msgPermissionsBranch")) +
                      " "
                  )
                ])
              ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }